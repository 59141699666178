.BigSliderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 1000px;
  max-width: 68vw;
  margin-top: 50px;
}

.BigSlider {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 900px;
}

.BigSliderList div:first-child {
  opacity: 1;
  z-index: 10;
}

.BigSliderList div:target {
  opacity: 1;
  z-index: 10;
}

.BigSliderList {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  min-height: 70vh;
  padding-left: 0px;
  width: 100%;
}

.BigSliderList div {
  padding: 0px 10px;
  margin: 0px 0px 0px 3px;
}

.BigSliderList li::marker {
  font-size: 10px;
  font-weight: 900;
  margin: 0;
  padding: 0;
}

.BigSliderSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  width: 800px;
  max-width: 50vw;
  opacity: 0;
  height: 500px;
  max-height: 60vh;
  min-height: max-content;
  transition: opacity .005s;
  background: #e4e4e4;
}

.BigSliderSection .BigSliderHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: inherit;
  width: inherit;
  max-width: inherit;
}

.BigSliderSection .BigSliderHeader .BigSliderSpeaker {
  width: 180px;
  transform: scale(1.3) translate(30px, -30px);
  opacity: inherit;
  object-fit: contain;
}

.BigSliderSection .BigSliderHeader h2 {
  max-width: 80%;
}

.divLine {
  width: auto;
  transform: none;
  max-width: 80%;
}

.BigSliderSection .BigSliderHeader div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.BigSliderSection .BigSliderContent {
  overflow-y: scroll;
  max-height: 40vh;
}

.BigSliderSection .BigSliderContent li {
  font-size: 15px;
  margin-bottom: 15px;
}

.BigSliderCloseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  background-color: transparent;
  border: 1px rgb(204, 204, 204) solid;
  padding: 4px 8px;
  font-weight: 900;
  font-size: 20px;
  height: 40px;
  width: 40px;
}

.BigSliderCloseButtonContainer {
  position: relative;
  height: 40px;
  width: 40px;
  opacity: 1;
  top: 0vh;
  right: 0px;
  align-self: flex-end;
  margin-bottom: 10px !important;
}


@media(orientation: portrait) {
  .BigSliderSection .BigSliderContent {
    overflow-y: auto;
    padding: 5px 15px;
    height: 270px;
    max-height: 34vh;
  }
}

@media (max-width: 100px), (max-height: 540px), (orientation: portrait) {
  .BigSliderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 350px;
    height: 350px;
    max-height: 55vh;
    max-width: 100vw;
    margin-top: 30px;
  }
  .BigSliderButtons {
    width: 5%;
  }
  .BigSliderButtons img {
    width: 15px;
  }
  .BigSlider {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    max-width: 90vw;
    height: 300px;
  }
  .BigSliderList div:first-child {
    opacity: 1;
  }
  .BigSliderList div:target {
    opacity: 1;
  }
  .BigSliderList {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    height: 300px !important;
    max-height: 300px;
    min-height: auto;
    padding-left: 0px;
    width: 90%;
  }
  .BigSliderList div {
    padding: 0px 0px;
    margin: 0px 0px 0px 0px;
  }
  .BigSliderList li::marker {
    color: #5ac0e9;
    font-size: 10px;
    font-weight: 900;
    margin: 0;
    padding: 0;
  }
  .BigSliderSection {
    position: absolute;
    width: 300px;
    max-width: 90vw;
    opacity: 0;
    height: auto;
    min-height: 10vh;
    max-height: 50vh;
    transition: opacity .5s;
    background: #e4e4e4;
    border-radius: 15px 0px 0px 0px;
  }
  .BigSliderSection .BigSliderHeader {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    opacity: inherit;
  }
  .BigSliderSection .BigSliderHeader .BigSliderSpeaker {
    width: 80px;
    opacity: inherit;
    transform: scale(1.5) translate(13px, -18px);
  }
  .BigSliderSection .BigSliderHeader div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 200px;
    padding: 10px 10px 10px 10px;
    background: rgb(110, 97, 33);
    background: linear-gradient(90deg, rgba(110, 97, 33, 1) 0%, rgba(230, 194, 72, 1) 15%, rgba(230, 194, 72, 1) 85%, rgba(110, 97, 33, 1) 100%);
    border-radius: 0px 0px 0px 15px;
  }
  .BigSliderSection .BigSliderHeader div h1 {
    font-size: 18px;
    text-align: end;
    width: 87%;
  }
  .BigSliderSection .BigSliderHeader div h2 {
    font-size: 14px;
    text-align: end;
  }
  .BigSliderSection .BigSliderHeader h2 {
    max-width: 90%;
  }
  .BigSliderSection .BigSliderContent {
    overflow-y: auto;
    padding: 5px 15px;
    height: 270px;
  }
  ul {
    padding-inline-start: 10px
  }
  .BigSliderBio {
    height: max-content;
  }
  .BigSliderSection .BigSliderContent p {
    font-size: 10px;
  }
  .BigSliderCloseButtonContainer {
    position: absolute;
    height: 0px;
    width: 0px;
    opacity: 1;
    top: 0px;
    right: 0px;
    transform: translate(-20px, -22px);
    z-index: 99999;
  }
  .BigSliderCloseButton {
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    background-color: transparent;
    border: 1px rgb(204, 204, 204) solid;
    padding: 4px 8px;
    border-radius: 50%;
    font-weight: 900;
    font-size: 15px;
    height: 20px;
    width: 20px;
    font-weight: 700;
  }
}