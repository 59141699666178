@font-face {
  font-family: WT Gothic;
  src: url('./fonts/WTGothic-Variable.woff');
}

@font-face {
  font-family: Calibirb;
  src: url('./fonts/calibrib.woff');
}

@font-face {
  font-family: BrandonGrotesque;
  src: url('./fonts/brandon-grotesque-light.woff');
}

body {
  margin: 0;
  font-family: Calibirb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */

*::-webkit-scrollbar-track {
  background: #e2e2e266;
}

/* Handle */

*::-webkit-scrollbar-thumb {
  background: #0031a1;
  border-radius: 10px;
}

/* Handle on hover */

*::-webkit-scrollbar-thumb:hover {
  background: #003ecf;
}

#root {
  overflow-x: hidden;
}

input {
  outline: none;
  border: 0;
  padding: 0;
}

.pointer {
  cursor: pointer;
}

img {
  object-fit: contain;
}

button, select {
  cursor: pointer;
  outline: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.gradientBackground {
  background: rgb(110, 97, 33);
  background: linear-gradient(90deg, rgba(110, 97, 33, 1) 0%, rgba(230, 194, 72, 1) 10%, rgba(230, 194, 72, 1) 90%, rgba(110, 97, 33, 1) 100%);
}

.centraBackground {
  background-color: #002e6d;
}

.centraColor {
  color: #002e6d;
}

.skyColor {
  color: #5ac0e9;
}

.whiteColor {
  color: white;
}

.yellowColor {
  color: #ecd589;
}

.goldColor {
  color: #e6c248;
}

.bold {
  font-weight: 900;
}

.semiBold {
  font-weight: 600;
}

.thinBold {
  font-weight: 100;
}