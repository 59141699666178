.memoriesContainer {
  position: relative;

  widows: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column;

  background-image: url('../../media/bg/bgMemoriasEd.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.memoriesContainer .memoriesHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95vw;
  margin: 10px auto 0 auto;
}

.memoriesContainer .memoriesHeader img {
  height: 4vw;
}

/* ------------------------ */

.memoriesContainer .content {
  width: 100%;
  height: 100%;

  margin: auto;

  display: flex;
  flex-flow: column;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #003ecf transparent;
}

.memoriesContainer .logoAndMenu {
  display: none;
  visibility: hidden;
}

/* ------------------------- */

.memoriesContainer .content .container {
  width: 100%;

  margin: 3vw auto;

  display: flex;
}

.memoriesContainer .content .iframeContainer {
  width: 60%;

  display: flex;
  flex-flow: column;

  margin: auto;
}

.memoriesContainer .content .iframeContainer .loadingCircle {
  margin: 30px auto;
}

.memoriesContainer .content .iframeContainer .iframe {
  width: 50vw;
  height: calc(50vw / 1.7777);

  margin: auto;
  background-color: #002D69;
  border: 1px solid #ECD589;

  display: flex;
}

.memoriesContainer .content .iframeContainer .iframe h4 {
  color: #ECD589;
  font-size: 2vw;
  text-align: center;
  width: 100%;
  margin: auto;
}

.memoriesContainer .content .iframeContainer .iframe iframe {
  width: 99%;
  height: 100%;

  margin: auto;
}

.memoriesContainer .content .iframeContainer .RoomLanguage {
  margin: 10px auto auto 8%;
}

/* --------------------------------------- */

.memoriesContainer .content .sliders {
  width: 35%;

  margin: 0px auto auto 0;

  display: flex;
  flex-flow: column;
}

.memoriesContainer .content .sliders h3 {
  width: 95%;

  margin: 0px auto 10px auto;

  text-align: center;
  font-size: 2vw;
}

.memoriesContainer .content .sliders .days {
  width: 100%;
  margin: 0px auto 30px auto;

  display: flex;
  justify-content: center;
  flex-flow: column;
}

.memoriesContainer .content .sliders .days .actions {
  width: 100%;

  margin: auto;

  display: flex;
  align-items: center;
}

.memoriesContainer .content .sliders .days .actions .titleDek {
  width: 25%;

  margin: auto;

  cursor: pointer;
}

.memoriesContainer .content .sliders .days .actions .selected {
  width: 33%;

  margin: auto;
}

.memoriesContainer .content .sliders .days .actions .titleDek h2 {
  width: 100%;
  margin: 0px auto;
  padding: 3px 0px;
  text-align: center;
  font-size: 1.2vw;
  color: #FFF;
  background-color: #002D69;
}

.memoriesContainer .content .sliders .days .actions .titleDek div {
  width: 100%;
  height: 10px;

  margin: 2px auto;
}

.memoriesContainer .content .message {
  margin: 10px auto;

  width: 60%;

  background: rgb(110, 97, 33);
  background: linear-gradient(90deg, rgba(110, 97, 33, 1) 0%, rgba(230, 194, 72, 1) 10%, rgba(230, 194, 72, 1) 90%, rgba(110, 97, 33, 1) 100%);
}

.memoriesContainer .content .message h2 {
  font-size: 1.2vw;
  line-height: 1.2vw;
  margin: 5px 30px;
  text-align: center;
  color: #002D69;
}

.memoriesContainer .content .message h2::before {
  content: "DESLICE A LOS LADOS PARA VER MÁS CONFERENCIAS";
}


/* ======================================================= */
/* ======================================================= */
/* ===================== RESPONSIVE ====================== */
/* ======================================================= */
/* ======================================================= */


@media screen and (max-width: 1024px) {
  .memoriesContainer {
    background-image: url('../../media/backgroundMobile2.png');
  }

  .memoriesContainer .memoriesHeader img {
    height: 8vw;
  }

  .memoriesContainer .logoAndMenu {
    width: 100%;

    margin: 30px auto;

    display: flex;
    justify-content: center;
    align-items: center;

    visibility: visible;
  }

  .memoriesContainer .logoAndMenu .memoriesCentralLogo {
    width: 45vw;
  }

  /* ------------------------- */

  .memoriesContainer .content .container {
    display: flex;
    flex-flow: column;

    margin: 10px auto;
  }

  .memoriesContainer .content .iframeContainer {
    width: 100%;
    margin: 10px auto;
  }

  .memoriesContainer .content .iframeContainer .iframe {
    width: 75vw;
    height: calc(75vw / 1.7777);
  }

  .memoriesContainer .content .iframeContainer .RoomLanguage {
    margin: 10px auto auto 12%;
  }

  /* --------------------------------------- */

  .memoriesContainer .content .sliders {
    width: 100%;
    margin: auto;
  }

  .memoriesContainer .content .sliders h3 {
    width: 95%;

    margin: 5px auto 10px auto;

    text-align: center;
    font-size: 3vw;
  }

  .memoriesContainer .content .sliders .days {
    width: 100%;
    margin: 0 auto 30px auto;

    display: none;
  }

  .memoriesContainer .content .sliders .dayRes {
    width: 75%;

    margin: auto;
  }

  .memoriesContainer .content .sliders .day1,
  .memoriesContainer .content .sliders .day2,
  .memoriesContainer .content .sliders .day3 {
    width: 30%;

    margin: auto;
  }

  .memoriesContainer .content .message {
    background: #002D69;
    border: 1px solid #ECD589;
  }

  .memoriesContainer .content .message h2 {
    font-size: 2vw;
    line-height: 2vw;
    margin: 5px 30px;
    text-align: center;
    color: #ECD589;
  }

  .memoriesContainer .content .message h2::before {
    content: "DESLICE HACIA ABAJO PARA VER MÁS CONFERENCIAS";
  }
}

@media screen and (max-width: 640px) {
  .memoriesContainer .memoriesHeader img {
    height: 10vw;
  }

  .memoriesContainer .logoAndMenu {
    margin: 20px auto 0px auto;
  }

  .memoriesContainer .logoAndMenu .memoriesCentralLogo {
    width: 45vw;
  }

  /* ------------------------- */

  .memoriesContainer .content .iframeContainer {
    width: 100%;
    margin: 1vh auto;
  }

  .memoriesContainer .content .iframeContainer .iframe {
    width: 95vw;
    height: calc(95vw / 1.7777);
  }

  .memoriesContainer .content .iframeContainer .iframe h4 {
    width: 80%;
    font-size: 7vw;
  }

  .memoriesContainer .content .iframeContainer .RoomLanguage {
    margin: 10px auto 0 2.5%;
  }

  /* --------------------------------------- */

  .memoriesContainer .content .sliders h3 {
    font-size: 5vw;
  }

  .memoriesContainer .content .message h2 {
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
}
