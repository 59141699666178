.LandingContainer {
  height: 100vh;
  width: 100vw;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-image: url('../../media/bg/conteoBG.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: auto;
  color: white;
  overflow-x: hidden;
  font-family: Calibirb;
}

.LandingHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 99vw;
  padding: 10px 0px;
}

.LandingHeader img{
  height: 5vw;
}

.LandingContent {
  display: flex;
  height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 50px;
  max-width: 100vw;
}

.LandingTitle {
  text-align: center;
  font-size: calc(1em + 1.2vw);
  font-weight: 600;
  letter-spacing: 3px;
}

.LandingCentralLogo {
  width: 28vw;
  margin-bottom: 10px;
}

.LandingCounter {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  max-width: 100vw;
  font-family: WT Gothic;
}

.LandingCounter div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0vw 1vw;
  width: 15vw;
}

.LandingCounter div img {
  width: calc(2em + 8vw);
}

.LandingCounter div h3 {
  font-size: calc(1em + 1.2vw);
}

.LandingCounter div h4 {
  letter-spacing: 0.5vw;
  font-size: calc(1em + 0.5vw);
}

.LandingCounter hr {
  margin: 0px;
  align-self: flex-start;
  height: calc(1em + 1.2vw);
}

.LandingButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  text-align: center;
}

.LandingButtonContainer div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 30vw;
  width: 650px;
}

.LandingButtonContainer button {
  border: none;
  padding: 15px 50px;
  font-size: calc(0.5em + 0.8vw);
  margin-bottom: 10px;
  min-width: calc(1em + 14vw);
}

/* =========================================================== */
/* =========================================================== */
/* ======================== RESPONSIVE ======================= */
/* =========================================================== */
/* =========================================================== */


@media (orientation: portrait) {
  .LandingContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    background-image: url('../../media/backgroundMobile.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow-y: auto;
    color: white;
  }
}

@media (max-width: 100px), (max-height: 540px),(orientation: portrait) {
  .LandingHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95vw;
    margin: 10px auto;
    padding: 0px;
  }
  .LandingHeader img {
    margin-top: 10px;
    height: 35px !important;
    object-fit: contain;
  }
  .LandingContent {
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 50px;
    max-width: 100vw;
    margin: auto;
  }
  .LandingTitle {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin: 10px auto;
  }
  .LandingCentralLogo {
    max-width: 250px;
    width: auto;
    margin: 20px auto;
  }
  .LandingCounter {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: 100vw;
    margin: 30px auto;
  }
  .LandingCounter div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0vw 1vw;
    width: 120px;
  }
  .LandingCounter div img {
    width: 68px;
  }
  .LandingCounter div h3 {
    font-size: 32px;
  }
  .LandingCounter div h4 {
    letter-spacing: 4px;
    font-size: 16px;
  }
  .LandingCounter hr {
    margin: 0px;
    align-self: flex-start;
    height: 35px;
  }
  .LandingButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    text-align: center;
    margin-top: 15px;
  }
  .LandingButtonContainer .actions {
    flex-direction: column;
    width: 100%;
  }
  .LandingButtonContainer button {
    border: none;
    padding: 10px 40px;
    margin-bottom: 10px;
    font-size: 14px;
    min-width: 200px;
  }
  .LandingButtonContainer p {
    font-size: 11px;
  }
}
