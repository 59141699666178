* {
  outline: none;
}

.slideComponentRes {
  display: none;
}

@media screen and (max-width: 1024px) {
  .slideComponentRes {
    position: relative;
    width: 80%;

    margin: auto;

    display: block;
  }

  .slideComponentRes .slick-prev {
    position: absolute;
    top: 50px;
    left: -10%;

    width: auto;
    height: auto;

    transform: translate(0%, -50%);
    transform: rotateY(180deg);

    z-index: 10;
  }

  .slideComponentRes .slick-next {
    position: absolute;
    top: 85px;
    right: -10%;

    width: auto;
    height: auto;

    transform: translate(0%, -50%);

    z-index: 10;
  }

  .slick-next:before, .slick-prev:before {
    color: #ecd589;
    opacity: 1;
    font-size: 7vw;
    content: "▶";
  }

  .slideComponentRes .slick-dots {
    position: absolute;
    bottom: -10%;
  }

  .slideComponentRes .slick-dots li.slick-active button:before {
    opacity: .75;
    font-size: 1vw;
  }

  .slideComponentRes .slick-dots li button:before {
    color: #FFF;
    font-size: 1vw;
    opacity: .25;
  }

  /* ====================================================================== */

  .slideComponentRes .dayRes1,
  .slideComponentRes .dayRes2,
  .slideComponentRes .dayRes3 {
    display: flex;
    flex-flow: column;

    width: 100%;

    margin: auto;
  }

  .slideComponentRes .titleRes {
    width: 100%;

    margin: auto;
  }

  .slideComponentRes .titleRes h2 {
    width: 100%;
    margin: 0px auto;
    padding: 5px 0px;
    text-align: center;
    font-size: 3vw;
    color: #FFF;
    background-color: #002D69;
  }

  .slideComponentRes .titleRes div {
    width: 100%;
    height: 20px;

    margin: 3px auto;
  }

  /* --------------------- */

  .slideComponentRes .listSpeakers {
    width: 100%;
    height: 20vw;

    display: flex;
    flex-flow: column;

    margin: auto;

    overflow-x: hidden;
    overflow-y: auto;
  }
  .slideComponentRes .listSpeakers::-webkit-scrollbar,
  .slideComponentRes .listSpeakers::-webkit-scrollbar,
  .slideComponentRes .listSpeakers::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .slideComponentRes .listSpeakers .speakerContRes {
    width: 100%;

    background-color: #FFF2;
    border-bottom: 1px solid #FFF2;

    margin: auto;

    cursor: pointer;
  }

  .slideComponentRes .listSpeakers .speakerContRes .h2 {
    width: 100%;

    text-align: center;
    color: #ecd589;
    text-transform: uppercase;
    font-size: 2vw;

    margin: 10px auto auto auto;
  }

  .slideComponentRes .listSpeakers .speakerContRes .h3 {
    width: 100%;

    text-align: center;
    color: #FFF;
    font-size: 2.5vw;

    margin: 10px auto auto auto;
  }

  .slideComponentRes .listSpeakers .speakerContRes .p {
    width: 100%;

    text-align: center;
    color: #FFF;
    font-size: 1.5vw;

    margin: auto auto 10px auto;
  }
}

@media screen and (max-width: 768px) {
  .slideComponentRes {
    width: 80%;
  }

  .slideComponentRes .slick-prev {
    top: 60px;
    left: -10%;
    transform: translate(0%, -50%);
    transform: rotateY(180deg);
  }

  .slideComponentRes .slick-next {
    top: 85px;
    right: -10%;
    transform: translate(0%, -50%);
  }

  .slick-next:before, .slick-prev:before {
    font-size: 7vw;
  }

  /* ====================================================================== */

  .slideComponentRes .listSpeakers {
    width: 100%;
    height: 20vw;
  }

  .slideComponentRes .listSpeakers .speakerContRes .h2 {
    font-size: 2vw;
  }

  .slideComponentRes .listSpeakers .speakerContRes .h3 {
    font-size: 2.5vw;
  }

  .slideComponentRes .listSpeakers .speakerContRes .p {
    font-size: 1.5vw;
  }
}

@media screen and (max-width: 540px) {
  .slideComponentRes {
    width: 90%;
  }

  .slideComponentRes .slick-prev {
    top: 70px;
    left: -10%;
    transform: translate(0%, -50%);
    transform: rotateY(180deg);
  }

  .slideComponentRes .slick-next {
    top: 85px;
    right: -10%;
    transform: translate(0%, -50%);
  }

  .slick-next:before, .slick-prev:before {
    font-size: 7vw;
  }

  /* ====================================================================== */

  .slideComponentRes .titleRes h2 {
    font-size: 5vw;
  }

  .slideComponentRes .titleRes div {
    height: 20px;
  }

  .slideComponentRes .listSpeakers {
    width: 100%;
    height: 20vw;
  }

  .slideComponentRes .listSpeakers .speakerContRes .h2 {
    font-size: 4vw;
  }

  .slideComponentRes .listSpeakers .speakerContRes .h3 {
    font-size: 4.5vw;
  }

  .slideComponentRes .listSpeakers .speakerContRes .p {
    font-size: 3.5vw;
  }
}


@media screen and (max-width: 360px) {

  .slideComponentRes .slick-prev {
    top: 70px;
    left: -17%;
    transform: translate(0%, -50%);
    transform: rotateY(180deg);
  }

  .slideComponentRes .slick-next {
    top: 85px;
    right: -17%;
    transform: translate(0%, -50%);
  }

  .slick-next:before, .slick-prev:before {
    font-size: 12vw;
  }

  /* ====================================================================== */

  .slideComponentRes .titleRes h2 {
    font-size: 4.5vw;
  }

  .slideComponentRes .titleRes div {
    height: 10px;
    margin: auto;
  }

  .slideComponentRes .listSpeakers {
    width: 100%;
    height: 20vw;
  }

  .slideComponentRes .listSpeakers .speakerContRes .h2 {
    font-size: 4vw;
  }

  .slideComponentRes .listSpeakers .speakerContRes .h3 {
    font-size: 4.5vw;
  }

  .slideComponentRes .listSpeakers .speakerContRes .p {
    font-size: 3.5vw;
  }
}
