.LoginContainer {
  height: max-content;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../media/bg/loginBG.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: auto;
  overflow-x: hidden;
}

.LoginHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95vw;
  padding: 10px 0px;
}

.LoginHeader img {
  height: 5vw;
}

.LoginContent {
  display: flex;
  height: 65vh;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 100vw;
  margin: 40px auto;
}

.LoginLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
}

.LoginTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-bottom: 50px;
}

.LoginCentralLogo {
  width: 30vw;
}

.LoginRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
}

.LoginRight .suport {
  margin: 5px auto;
  width: 450px;
}

.LoginForm {
  display: flex;
  flex-direction: column;
  justify-content: unset;
  align-items: center;
  text-align: center;
  width: 350px;
  height: 380px;
  max-height: 45vh;
  min-height: 250px;
  padding: 10px 50px;
}

.LoginForm h3 {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 15px 0px;
  font-size: 20px;
  font-weight: 600;
  margin: auto;
}

.LoginForm input {
  background-color: white;
  width: 80%;
  padding: 15px 10%;
  border: none;
  border-radius: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin: auto;
}

.LoginForm button {
  border: none;
  border-radius: 10px;
  padding: 15px 50px;
  font-size: 16px;
  letter-spacing: 2px;
  margin: auto;
}

.LoginForm a {
  margin: auto;
}

.LoginSupButton {
  width: 140px;
  font-size: 10px;
  border: white solid 2px;
  background-color: transparent;
  border-radius: 100px;
  padding: 6px;
  margin-right: 10px;
  margin-top: 15px;
  align-self: start;
}

.LoginPopUp {
  visibility: visible;
  display: flex;
  width: 330px;
  height: auto;
  position: absolute;
  transform: translate(220px, -90px);
  opacity: 1;
}

.LoginPopUp2 {
  width: 0px;
  height: 0px;
  display: none;
  visibility: hidden;
  position: absolute;
  opacity: 0;
}


/* =========================================================== */
/* =========================================================== */
/* ======================== RESPONSIVE ======================= */
/* =========================================================== */
/* =========================================================== */

@media (orientation: portrait) {
  .LoginContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    background-image: url('../../media/backgroundMobile2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
}

@media (max-width: 100px), (max-height: 540px),(orientation: portrait) {
  .LoginHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95vw;
    padding: 0;
  }
  .LoginHeader img {
    margin-top: 10px;
    height: 35px !important;
  }
  .LoginContent {
    display: flex;
    height: max-content;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 0;
    max-width: 100vw;
    margin: 10px auto auto auto;
  }
  .LoginSeparator {
    height: 0px;
    width: 0px;
  }
  .LoginLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 99vw;
  }
  .LoginTitle {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 4px;
    margin: 30px 0px;
  }
  .LoginCentralLogo {
    width: 60vw;
    margin-bottom: 40px;
  }
  .LoginRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 99vw;
  }

  .LoginRight .suport {
    margin: 5px auto;
    width: 65vw;
  }

  .LoginForm {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 250px;
    padding: 10px 50px;
    min-width: 50vw;
  }
  .LoginForm h3 {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 15px 0px;
    font-size: 14px;
  }
  .LoginForm input {
    background-color: white;
    width: 80%;
    padding: 10px 10%;
    border: none;
    border-radius: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .LoginForm button {
    border: none;
    border-radius: 10px;
    padding: 10px 35px;
    font-size: 16px;
    letter-spacing: 2px;
  }
  .LoginSupButton {
    width: 130px;
    font-size: 10px;
    border: white solid 2px;
    background-color: transparent;
    border-radius: 100px;
    padding: 5px;
    margin-right: 10px;
    margin-top: 10px;
    align-self: start;
  }
  .LoginPopUp {
    visibility: visible;
    display: flex;
    width: 70vw;
    height: auto;
    position: absolute;
    transform: translate(10vw, -80px);
    opacity: 1;
  }
}

@media screen and (max-height: 630px) and (orientation: portrait) {
  .LoginContent {
    margin: 5px auto auto auto;
  }

  .LoginTitle {
    margin: 10px 0px;
  }

  .LoginCentralLogo {
    width: 60vw;
    margin-bottom: 20px;
  }
}