* {
  outline: none;
}

.slideComponent {
  position: relative;
  width: 70%;

  margin: 10px auto;
}

.slideComponent .slick-prev {
  position: absolute;
  top: 65px;
  left: -5%;

  width: auto;
  height: auto;

  transform: translate(0%, 10%) rotate(180deg);

  z-index: 10;
}

.slideComponent .slick-next {
  position: absolute;
  top: 85px;
  right: -5%;

  width: auto;
  height: auto;

  transform: translate(0%, -50%);

  z-index: 10;
}

.slick-next:before, .slick-prev:before {
  color: #ecd589;
  opacity: 1;
  font-size: 4vw;
  content: "▶";
}

.slideComponent .slick-dots {
  position: absolute;
  bottom: -10%;
}

.slideComponent .slick-dots li.slick-active button:before {
  opacity: .85;
  font-size: 10px;
}

.slideComponent .slick-dots li button:before {
  color: #FFF;
  font-size: 7px;
  opacity: .45;
}

/* ====================================================================== */

.slideComponent .speakerCont {
  display: flex;
  flex-flow: column;

  margin: auto;

  max-height: 230px;

  overflow-x: hidden;
  overflow-y: auto;
}

.slideComponent .speakerCont::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.slideComponent .speakerCont .imgSpk {
  width: 10vw;
  height: 10vw;

  border-radius: 200px;

  margin: 5px auto;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  cursor: pointer;
}

.slideComponent .speakerCont .title {
  width: 90%;

  display: flex;
  flex-flow: column;

  margin: auto;
}

.slideComponent .speakerCont h4 {
  color: #FFF;
  font-size: 1vw;
  text-align: center;

  width: 100%;
  margin: 5px auto;
}

.slideComponent .speakerCont .title {
  margin: auto auto 5px auto;

  border-bottom: 1px solid #FFF5;
}

.slideComponent .speakerCont .title h4 {
  color: #FFF;
  font-size: 1.3vw;
  text-align: center;

  width: 100%;
  margin: auto;
}

.slideComponent .speakerCont .title p {
  color: #FFF;
  font-size: .8vw;
  text-align: center;

  width: 100%;
  margin: auto auto 5px auto;
}

@media screen and (max-width: 1024px) {
  .slideComponent {
    display: none;
  }
}
