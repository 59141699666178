.SpeakersSliderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.SpeakersSliderContainer a {
  margin: 7% auto;
  height: min-content;
}

.SpeakersSlider {
  display: flex !important;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 63vw;
  scroll-behavior: smooth;
}

/* .sliderButtons {
  display: none;
  visibility: hidden;
} */

.SpeakersSliderSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  position: relative;
  width: 215px;
  height: inherit;
  transition: opacity .2s;
}

.SpeakersSliderSection .SpeakersInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 160px;
}

.SpeakersSliderSection img{
  width: 135px;
}

.SpeakersSliderSection button {
  border: 1px solid white;
  background-color: #002d6d62;
  padding: 10px;
  letter-spacing: 1px;
  text-shadow: black 0px -1px 4px;
  margin-top: 15px;
}

.SpeakersSliderSection div h2 {
  font-size: 18px;
  text-shadow: black 0px -2px 4px;
}

.SpeakersSliderSection div h4 {
  font-size: 12px;
  text-shadow: black 0px -2px 4px;
}

.SpeakersSliderSection div p {
  width: 230px;
  margin-top: 8px;
  font-size: 11px;
  overflow-wrap: break-word;
  text-shadow: black 0px -2px 4px;
}

.SpeakersSliderTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px !important;
}

.SpeakersSliderTitle h3 {
  width: 220px;
  text-align: center;
  margin-bottom: 1px;
}

.SpeakersSliderTitle div {
  height: 5px;
  padding: 0px !important;
}

.SpeakersSliderList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 380px;
  padding-left: 0px;
  width: 55vw;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.SpeakersSliderList::-webkit-scrollbar {
  display: none;
}

.SpeakersSliderList div, .SpeakersSliderList hr {
  background-color: transparent;
  padding: 0px 10px;
  margin: 0px 0px 0px 3px;
  color: white;
}

.SpeakersSliderList hr {
  border: 1px solid rgb(228, 228, 228);
}

.SpeakersSliderList li::marker {
  color: #5ac0e9;
  font-size: 10px;
  font-weight: 900;
  margin: 0;
  padding: 0;
}

.SpeakersSliderItem {
  font-size: 10px;
}

.SpeakersDurationMobile {
  color: #5ac0e9;
}

.SpeakersSliderEnd {
  width: 210px;
  padding: 5px !important;
  margin-bottom: 3px !important;
}

.SpeakersSliderEnd p {
  font-size: 8px;
  padding: 2px;
  text-shadow: black 0px -2px 4px;
}

@media (max-width: 100px), (max-height: 540px),(orientation: portrait) {
  .SpeakersSlider {
    display: flex;
    flex-direction: column;
    visibility: visible;
    width: 250px;
  }
  .SpeakersSliderButtons {
    display: block;
    visibility: visible;
    text-decoration: none;
    width: 10px;
  }
  .SpeakersSliderButtons img {
    width: 13px;
  }
  .SpeakersSliderList {
    width: 250px;
  }
  .SpeakersSliderContainer a {
    margin: 25% auto;
    height: min-content;
  }
}