.MenuContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background-color: transparent;
  color: white;
  padding: 0px 20px;
  margin-top: 50px;
}

.MenuItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  text-align: center;
  margin: auto 10px;
}

.MenuItem h4 {
  letter-spacing: 3px;
  font-size: 0.8vw;
  cursor: pointer;
  font-weight: 600 !important;
}

.MenuSeparator {
  font-size: 2vw;
  font-weight: 100;
}

.MenuPopUp1 {
  width: 0px;
  height: 0px;
  display: none;
  visibility: hidden;
  position: absolute;
  opacity: 0;
}

.MenuPopUp1v {
  visibility: visible;
  display: flex;
  width: 350px;
  max-height: 10vh;
  height: auto;
  position: absolute;
  transform: translate(4vw, -4vh);
  opacity: 1;
}

.MenuPopUp2 {
  width: 0px;
  height: 0px;
  display: none;
  visibility: hidden;
  position: absolute;
  opacity: 0;
}

.MenuPopUp2v {
  visibility: visible;
  display: flex;
  width: 350px;
  max-height: 10vh;
  height: auto;
  position: absolute;
  transform: translate(3vw, -4vh);
  opacity: 1;
}

@media (max-width: 100px), (max-height: 540px),(orientation: portrait) {
  .MenuContainer {
    height: 0px;
    width: 0px;
    margin: 0px;
    visibility: hidden;
    display: none;
  }
}

/* 1510 */