.AdminContainer {
  background-color: #13142a;
  min-height: 100vh;
  width: 100vw;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2vw;
  box-sizing: border-box;
}

.AdminContainer textarea {
  outline: none;
}

.AdminQuestions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.AdminContainer h1 {
  margin-bottom: 15px;
  font-size: calc(1em + 2vw);
}

.AdminContainer h2 {
  margin-bottom: 15px;
  font-size: calc(1em + 1vw);
}

.AdminContainer h4, .AdminContainer p {
  font-size: calc(1em + 0.5vw);
}

.AdminQuestions .AdminQuestionsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  min-height: 30vh;
  max-height: 70vh;
  background-color: rgba(143, 143, 143, 0.267);
  padding: 1vw;
  border-radius: 15px;
  width: 96vw;
  box-sizing: border-box;
  box-shadow: 0px 3px 8px rgb(119, 119, 119);
  overflow-y: auto;
}

.AdminQuestions .AdminQuestionsContent button {
  border-radius: 10em;
  border: none;
  box-sizing: border-box;
  height: calc(1rem + 0.8vw);
  width: calc(1rem + 0.8vw);
  font-size: calc(1rem + 0.4vw);
  padding: 0;
  font-weight: 900;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(226, 95, 95);
}

.AdminQuestions .AdminQuestionsContent div {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px dotted white;
  padding: 0.5vw 0px;
}

.AdminQuestions .AdminQuestionsContent div p {
  word-wrap: break-word;
  width: 45%;
}

.AdminIframe, .AdminConnected {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.AdminIframe .AdminIframeColumns {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.AdminIframe .AdminIframeColumns .AdminIframeLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1vw;
  width: 40vw;
}

.AdminIframe .AdminIframeColumns .AdminIframeRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1vw;
  width: 40vw;
}

.iframe1 iframe, .iframe2 iframe {
  margin-top: 10px;
  width: 20vw;
  height: calc(20vw / 1.7777777);
}

.AdminIframe textarea {
  width: 30vw;
  padding: 0.5vw;
}

.AdminIframe button, .AdminRegister button {
  margin: 5px;
  border: solid white 1px;
  background-color: rgba(100, 100, 100, 0.363);
  color: white;
  font-size: calc(1rem + 0.5vw);
  padding: 0.5vw 1vw;
}

.AdminIframe button:hover, .AdminRegister button:hover {
  background-color: rgb(156, 156, 156);
}

.AdminRegister {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AdminRegisterContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  min-height: 30vh;
  max-height: 70vh;
  background-color: rgba(143, 143, 143, 0.267);
  padding: 1vw;
  border-radius: 15px;
  width: 80vw;
  box-sizing: border-box;
  box-shadow: 0px 3px 8px rgb(119, 119, 119);
  overflow-y: auto;
}

.AdminRegisterContent div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  border-bottom: 1px dotted white;
  padding: 0.4vw 0px;
}

.AdminRegisterContent div p {
  width: 50%;
}

.AdminRecords {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.AdminRecords div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AdminRecords button, .AdminRegister button {
  margin: 5px;
  border: solid white 1px;
  background-color: rgba(100, 100, 100, 0.363);
  color: white;
  font-size: calc(1rem + 0.5vw);
  padding: 0.5vw 1vw;
}

.AdminRecords button:hover, .AdminRegister button:hover {
  background-color: rgb(156, 156, 156);
}

.noRecording {
  background-color: rgb(109, 109, 109);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.recording {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation-name: changeColor;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-bottom: 10px;
}
@keyframes changeColor {
  0% {
	  background: rgb(255, 218, 218);
	}
	33.3333% {
	  background: rgb(255, 0, 0);
	}
	66.666% {
	  background: red;
	}
	100% {
	  background: rgb(255, 218, 218);
	}
}