.MobileMenuContainer {
  height: 0px;
  width: 0px;
  margin: 0px;
  visibility: hidden;
  display: none;
}

.MobileMenuIcon {
  height: 80%;
  width: 80%;
}

@media (max-width: 100px), (max-height: 540px),(orientation: portrait) {
  .MobileMenuContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: transparent;
    color: white;
    visibility: visible;
    height: auto;
    width: auto;
    margin-top: 0px;
    margin-left: 8px;
  }
  .MobileMenuDeploy {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    font-size: 30px;
    font-weight: 900;
    background-color: #e6c248;
    width: 30px;
    height: 35px;
    margin-left: 5px;
  }
  .MobileMenuDeploy p {
    text-shadow: rgb(255, 255, 255) -1px 0, rgb(255, 255, 255) 0 0px;
  }
  .MobileMenuContent {
    display: none;
    visibility: hidden;
  }
  .MobileMenuItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 90px;
    padding: 4px 0px;
    text-align: center;
  }
  .MobileMenuItem h4 {
    width: 90px;
    letter-spacing: 1px;
    font-size: 9px;
    cursor: pointer;
    text-align: end;
    word-wrap: break-word;
  }
  .MobileMenuSeparator {
    font-size: 35px;
    font-weight: 100;
  }
  .MobileMenuDeployed {
    padding: 5px;
    border-radius: 8px;
    width: 100px;
    background-color: rgba(4, 33, 84, 0.863)
  }
  .MobileMenuBorder {
    border-bottom: 2px rgb(255, 255, 255) solid;
  }
  .MenuMobilePopUp1 {
    width: 0px;
    height: 0px;
    display: none;
    visibility: hidden;
    position: absolute;
    opacity: 0;
  }
  
  .MenuMobilePopUp1v {
    visibility: visible;
    display: flex;
    width: 200px;
    max-height: 20vh;
    height: auto;
    position: absolute;
    transform: translate(-30vw, -6vh);
    opacity: 1;
  }
  
  .MenuMobilePopUp2 {
    width: 0px;
    height: 0px;
    display: none;
    visibility: hidden;
    position: absolute;
    opacity: 0;
  }
  
  .MenuMobilePopUp2v {
    visibility: visible;
    display: flex;
    width: 200px;
    max-height: 20vh;
    height: auto;
    position: absolute;
    transform: translate(-30vw, -6vh);
    opacity: 1;
  }
}

/* 1510 */