.RoomContainer {
  height: max-content;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: unset;
  align-items: center;
  background-image: url('../../media/FondoLanding4.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: auto;
  overflow-x: hidden;
}

.RoomHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 97vw;
  margin: 10px auto 5vh auto;
}

.RoomHeader img {
  height: 5vw;
}

.RoomContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding-bottom: 0px;
  max-width: 100vw;
  width: 90vw;
}

.RoomLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 60vw;
}

.RoomLeft .RoomLeftInside {
  display: none;
  visibility: hidden;
}

.RoomTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-bottom: 50px;
  padding: 15px;
}

.RoomCentralLogo {
  width: 24vw;
  margin-bottom: 200px;
}

.RoomRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 25vw;
  height: max-content;
  min-height: 50vh;
  overflow-x: hidden;
  padding-top: 4vh;
}

.RoomLanguage {
  align-self: flex-start;
  margin-left: 0vw;
  outline: none;
  width: 200px;
  border: solid white 1px;
  border-radius: 18px;
  background-color: #002d6d85;
  color: white;
  padding: 5px;
  font-weight: 700;
  font-size: 12px !important;
}

.RoomLanguage {
  background-image: url('../../media/roomArrow.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.RoomQuestion {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 20vw;
  border-radius: 10px;
}

.RoomQuestion textarea {
  width: 100%;
  height: 20vh;
  border-radius: 10px 10px 0px 0px;
  resize: none;
  outline: none;
  border: none;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgb(235, 235, 235);
  font-size: 20px;
  font-weight: 700;
  color: #002e6d;
  margin: 10px auto auto auto;
}

.RoomQuestion button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  border: none;
  padding: 0px 30px;
  border-radius: 0px 0px 10px 10px;
  font-weight: 800;
}

.RoomQuestion button p {
  font-size: 25px;
}

.RoomDesktop {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.RoomDesktopTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 10px !important;
  height: 75px;
}

.RoomDesktopTitle h3 {
  width: 250px;
  text-align: center;
  margin-bottom: 1px;
}

.RoomDesktopTitle div {
  height: 5px;
  width: 250px;
  margin: 0px !important;
  padding: 0px !important;
}

.RoomList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 60vh;
  padding-left: 0px;
  width: 250px;
}

.RoomList div .RoomItem {
  height: 75px;
}

.RoomList div, .RoomList hr {
  background-color: white;
  padding: 0px 20px;
  margin: 0px 0px 0px 3px;
}

.RoomList hr {
  border: 1px solid rgb(228, 228, 228);
}

.RoomList li::marker {
  color: #5ac0e9;
  font-size: 12px;
  font-weight: 900;
  margin: 0;
  padding: 0;
}

.RoomItem {
  font-size: 14px;
}

.RoomDuration {
  color: #5ac0e9;
}

.RoomDesktopEnd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 75px;
  padding: 0px 15px !important;
  margin-bottom: 3px !important;
}

.RoomDesktopEnd p {
  font-size: 10px;
  padding: 8px;
  width: 100%;
}

.RoomCentralLogo2 {
  width: 20vw;
}

.iframe {
  width: 57vw;
  height: calc(57vw / 1.77777);
  margin-bottom: 15px;
  min-height: max-content;
}

.iframe iframe {
  width: 57vw;
  height: calc(57vw / 1.77777);
}

.iframe .iframeIMG {
  width: 100%;
  height: 100%;
}

.QuestionPopUp {
  visibility: visible;
  display: flex;
  width: 350px;
  max-height: 10vh;
  height: auto;
  position: absolute;
  transform: translate(0vw, 3vh);
  opacity: 1;
}

.QuestionPopUpH {
  visibility: hidden;
  display: none;
  opacity: 0;
}

/* MOBILE */

@media (orientation: portrait) {
  .RoomContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-image: url('../../media/backgroundMobile.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: auto;
  }
}

@media (max-width: 100px), (max-height: 540px), (orientation: portrait) {
  .RoomHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 99vw;
  }
  .RoomHeader img {
    margin-top: 10px;
    height: 35px !important;
  }
  .RoomContent {
    display: flex;
    height: max-content;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 20px;
    max-width: 100vw;
  }
  .RoomSeparator {
    height: 0px;
    width: 0px;
  }
  .RoomLeft {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 99vw;
    margin-bottom: 20px;
  }
  .RoomLeft .RoomLeftInside {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    visibility: visible;
    margin: 30px 0px;
  }
  .RoomTitle {
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 10px 0px;
    padding: 5px;
  }
  .RoomCentralLogo {
    width: 40vw;
    margin-bottom: 0px;
  }
  .RoomRight {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 99vw;
    height: auto;
    min-height: auto;
    padding: 0;
  }
  .RoomRightInside {
    display: none;
    visibility: hidden;
  }
  .RoomLanguage {
    align-self: flex-start;
    margin-left: 5vw;
    outline: none;
    width: 200px;
    border: solid white 1px;
    border-radius: 18px;
    background-color: #002d6db7;
    color: white;
    padding: 5px;
    font-weight: 700;
  }
  .RoomQuestion {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90vw;
    height: 100px;
    border-radius: 10px;
  }
  .RoomQuestion textarea {
    width: 100%;
    height: 60vh;
    border-radius: 10px 10px 0px 0px;
    resize: none;
    outline: none;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    background-color: rgb(235, 235, 235);
    font-size: 14px;
    font-weight: 700;
  }
  .RoomQuestion button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    border: none;
    padding: 0px 30px;
    border-radius: 0px 0px 10px 10px;
    font-weight: 800;
  }
  .RoomQuestion button p {
    font-size: 25px;
  }
  .iframe {
    width: 90vw;
    height: calc(90vw / 1.77777);
    margin: 0px auto 20px auto;
  }
  .iframe iframe {
    width: 99%;
    height: 99%;
  }
  .QuestionPopUp {
    visibility: visible;
    display: flex;
    width: 300px;
    max-height: 12vh;
    height: auto;
    position: absolute;
    transform: translate(0vw, -4vh);
    opacity: 1;
  }
}

@media screen and (max-height: 630px) and (orientation: portrait) {
  .RoomHeader {
    width: 98vw;
    margin: 10px auto 0 auto;
  }
  .iframe {
    width: 98vw;
    height: calc(98vw / 1.77777);
    margin: 0px auto 20px auto;
  }
  .RoomLeft .RoomLeftInside {
    margin: 15px 0px;
  }
}