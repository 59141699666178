.RegisterContainer {
  height: max-content;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-image: url('../../media/bg/registroBG.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: auto;
  color: white;
  overflow-x: hidden;
  margin: auto;
}

.RegisterHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95vw;
  padding: 10px 0px;
}

.RegisterHeader img {
  object-fit: contain;
  height: 4vw;
}

.RegisterContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: 100vw;
  height: 100%;
}

.RegisterCentralLogo {
  max-width: 22vw;

  margin: 20px auto;
}

.RegisterInputs {
  position: relative;
  display: flex;
  flex-direction: column;
}

.RegisterInputs .popUpregisterYet-close {
  display: none;
}

.RegisterInputs .popUpregisterYet-open {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 20vw;
  height: calc(20vw / 3.0349);

  background-image: url('../../media/popupRegisterYet.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  z-index: 1000;

  cursor: pointer;

  transform: translate(-50%, -50%);
}

.RegisterContInput {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 7px;
}

.RegisterContInput div {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  width: 10vw;
  min-width: 120px;
  font-size: 12px;
  font-family: WT Gothic;
  font-weight: 800;
  height: auto;
}

.RegisterContInput input, .RegisterContInput select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32vw;
  font-size: 14px;
  max-width: 60vw;
  padding: 8px 0px;
  padding-left: 10px;
  color: #002e6d;
  font-weight: 700;
  font-family: WT Gothic;
  box-sizing: content-box;
}

.RegisterPopUp1 {
  width: 0px;
  height: 0px;
  display: none;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

#name:focus~.RegisterPopUp1 {
  visibility: visible;
  display: flex;
  width: 350px;
  height: auto;
  position: absolute;
  top: -115px;
  left: 30%;
  opacity: 1;
}

.RegisterContInput select {
  background-image: url('../../media/arrow.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
  /*Evita que se muestre la flecha por defecto en versiones de IE*/
}

.RegisterFinalSection {
  display: flex;
  flex-direction: row;
}

.RegisterFinalLeft {
  display: flex;
  flex-direction: column;
  width: 32vw;
}

.RegisterLeftButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px auto;
}

.RegisterLeftButtons button {
  font-size: .7vw;
  border: white solid 2px;
  background-color: transparent;
  border-radius: 100px;
  padding: 4px;
  margin-right: 10px;
}

.RegisterLeftChecks {
  display: flex;
  flex-direction: column;
}

.RegisterLeftChecks input {
  outline: none;
}

.RegisterLeftChecks label {
  font-size: 13px;
}

.RegisterFinalRight {
  display: flex;
  flex-direction: column;
  width: 10vw;
  height: max-content;
  margin: 0px 10px;
}

.RegisterFinalRight .RegisterSendButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 auto auto;

  background-color: transparent;
  border: none;
  outline: none;
}

.RegisterFinalRight .RegisterSendButton h2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding: 5px 40px;
  padding-left: 10px;
  border: rgba(230, 194, 72, 1) solid 2px;
  background-color: white;
  cursor: pointer;
  height: 20px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 2px;
  box-sizing: content-box;
}

.RegisterFloatButton {
  position: absolute;
  top: 50%;
  right: 0;

  border: 0;
  background-color: #FFF;
  box-shadow: black 0px 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 0px;
  height: 0px;
  font-size: 50px;
  padding: 20px;

  transform: translate(0%, -50%);
}

.RegisterFloatButton img {
  width: 18px;
  height: 18px;
}

.supButton {
  margin: auto;
  font-size: 12px;
  border: none;
  background-color: transparent;
  padding: 5px 25px;
  font-weight: 900;
}

.SupButtonContainer {
  position: relative;

  margin: 20px auto;
}


/* =========================================================== */
/* =========================================================== */
/* ======================== RESPONSIVE ======================= */
/* =========================================================== */
/* =========================================================== */

@media (orientation: portrait) {
  .RegisterContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    background-image: url('../../media/backgroundMobile.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: auto;
    color: white;
  }
}

@media (max-width: 100px), (max-height: 540px), (orientation: portrait) {
  .RegisterContainer {
    overflow-x: hidden;
  }
  .RegisterHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95vw;
    padding: 0;
    margin: 0 auto;
  }
  .RegisterHeader img {
    margin-top: 10px;
    height: 35px !important;
  }
  .RegisterContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 0px;
    max-width: 100vw;
    height: auto;
    margin: 30px auto 10px auto;
  }
  .RegisterTitle {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
  .RegisterCentralLogo {
    margin: 20px auto 20px auto;
    max-width: 150px;
  }

  .RegisterContInput {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
  }

  .RegisterInputs .popUpregisterYet-open {
    width: 75vw;
    height: calc(75vw / 3.0349);
  }

  .RegisterContInput div {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    width: 110px;
    font-size: 11px;
    max-width: 40vw;
    font-weight: 900 !important;
  }
  .RegisterContInput input, .RegisterContInput select {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    font-size: 12px;
    max-width: 60vw;
    padding-left: 10px;
    background-color: white;
  }
  #name:focus~.RegisterPopUp1 {
    visibility: visible;
    display: flex;
    width: 250px !important;
    height: auto;
    position: absolute;
    top: 0px;
    left: 10%;
    opacity: 1;
  }
  .RegisterFinalSection {
    display: flex;
    flex-direction: column-reverse;
    margin: 10px auto auto auto;
    width: 97%;
  }
  .RegisterFinalLeft {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0px auto;
  }
  .RegisterLeftButtons {
    display: flex;
    flex-direction: row;
  }
  .RegisterLeftButtons button {
    width: 105px;
    font-size: 7px;
    border: white solid 2px;
    background-color: transparent;
    border-radius: 100px;
    padding: 2px 2px;
    margin-right: 4px;
  }
  .RegisterLeftChecks {
    display: flex;
    flex-direction: column;
  }
  .RegisterLeftChecks div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .RegisterLeftChecks input {
    outline: none;
  }
  .RegisterLeftChecks label {
    font-size: 10px;
  }
  .RegisterFinalRight {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    width: 80%;
    margin: auto 0 0px auto;
  }
  .RegisterFinalRight .RegisterSendButton {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 0 auto auto;

    border: none;
    outline: none;
  }

  .RegisterFinalRight .RegisterSendButton h2 {
    padding: 3px 30px;
    padding-left: 10px;
    font-size: 12px;
  }

  .RegisterFloatButton {
    font-size: 50px;
    padding: 15px;
  }

  .RegisterFloatButton img {
    width: 18px;
    height: 18px;
  }

  #name:focus~.RegisterPopUp1 {
    visibility: visible;
    display: flex;
    width: 90vw;
    height: auto;
    position: absolute;
    transform: translate(0px, -100px);
    opacity: 1;
  }

  .SupButtonContainer {
    position: relative;
    left: auto;
    top: auto;
    height: auto;
    width: auto;
    margin: 10px auto;
  }

  .supButton {
    width: auto;
    font-size: 15px;
    border: none;
    background-color: transparent;
    padding: 5px 15px;
    font-weight: 900;
    margin: auto;
    transform: translate(0%);
  }
}

@media screen and (max-height: 630px) and (orientation: portrait) {
  .RegisterCentralLogo {
    margin: 0px auto 10px auto;
    max-width: 150px;
  }

  .RegisterContent {
    margin: 20px auto 0px auto;
  }

  .RegisterContInput div {
    letter-spacing: 1px;
    width: 100px;
    font-size: 10px;
    max-width: 40vw;
  }

  .RegisterContInput input, .RegisterContInput select {
    width: 240px;
    font-size: 10px;
    max-width: 55vw;
    padding-left: 10px;
  }

  .supButton {
    font-size: 12px;
  }
}
