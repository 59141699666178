.MemoriasAdminCont {
  width: 100%;

  margin: 50px auto;

  display: flex;
  flex-flow: column;

  border-radius: 20px;
  background: #13142a;
  box-shadow:  7px 7px 14px #0d0e1d,
              -7px -7px 14px #191a37;
}

.MemoriasAdminCont h1 {
  width: 100%;

  margin: 30px auto 10px auto;

  font-size: 3.5vw;
  text-align: center;
}

.MemoriasAdminCont .slideCheckCont {
  width: 100%;

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.MemoriasAdminCont .slideCheckCont h2 {
  margin: 10px 30px;
  font-size: 1.5vw;
  color: #35375e;
}

.MemoriasAdminCont .slideCheckCont .selected {
  color: #717396;
}

/* ======================================================== */

.MemoriasAdminCont .container {
  width: 100%;

  margin: 10px auto 30px auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.MemoriasAdminCont .container div[class^="dayCont"] {
  width: 30%;

  margin: auto;

  border: none;
  border-top: 1px solid #191a37;

  display: flex;
  flex-flow: column;
}

.MemoriasAdminCont .container div[class^="dayCont"] h2 {
  width: 100%;

  text-align: center;
  color: #35375e;
  font-size: 2vw;
}

.MemoriasAdminCont .container div[class^="dayCont"] .btnCont {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.MemoriasAdminCont .container div[class^="dayCont"] .btnCont .btn {
  border: none;
  outline: none;
  border-radius: 5px;
  background: linear-gradient(145deg, #111226, #14152d);
  box-shadow:  3px 3px 10px #0d0e1d,
              -3px -3px 10px #191a37;

  margin: 10px auto;
  padding: 10px 20px;

  color: #454663;
  font-size: 1.5vw;
  letter-spacing: 1px;
}

.MemoriasAdminCont .container div[class^="dayCont"] .btnCont .selected {
  border-radius: 5px;
  background: #13142a;
  box-shadow: inset 3px 3px 10px #0d0e1d,
              inset -3px -3px 10px #191a37;

  color: #3d3f61;
}

.MemoriasAdminCont .inpustCont {
  width: 100%;

  margin: 10px auto;

  display: flex;
  flex-flow: column;
}

.MemoriasAdminCont .inpustCont .input {
  width: 90%;

  margin: auto;

  display: flex;
}

.MemoriasAdminCont .inpustCont .input input {
  width: 90%;

  margin: 5px auto;
  padding: 5px 10px;

  background-color: transparent;

  border: none;
  outline: none;
  border-bottom: 1px solid #35375e;
  color: #acaed4;
}

.MemoriasAdminCont .inpustCont .input input::placeholder {
  color: #35375e;
}

.MemoriasAdminCont .inpustCont .input input:focus {
  border-bottom: 1px solid #717396;
}

.MemoriasAdminCont .inpustCont .frame {
  width: 80%;

  margin: auto;

  display: flex;
}

.MemoriasAdminCont .inpustCont .frame .frameCont {
  margin: auto;
}

.MemoriasAdminCont .inpustCont .frame iframe {
  width: 30vw;
  height: calc(30vw / 1.77777);

  margin: 10px auto;
}

.MemoriasAdminCont .inpustCont .actions {
  width: 100%;

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.MemoriasAdminCont .inpustCont .actions .btnActions {
  border: none;
  outline: none;
  border-radius: 5px;
  background: linear-gradient(145deg, #111226, #14152d);
  box-shadow:  3px 3px 10px #0d0e1d,
              -3px -3px 10px #191a37;

  margin: 10px;
  padding: 10px 20px;

  color: #454663;
  font-size: 1.5vw;
  letter-spacing: 1px;
}

.MemoriasAdminCont .inpustCont .actions .btnActions:active {
  border-radius: 5px;
  background: #13142a;
  box-shadow: inset 3px 3px 10px #0d0e1d,
              inset -3px -3px 10px #191a37;

  color: #3d3f61;
}