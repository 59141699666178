@font-face {
  font-family: BrandonGrotesque;
  src: url('../../fonts/brandon-grotesque-light.woff');
}

.CertificateContainer {
  height: max-content;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-image: url('../../media/bg/certificadoBG.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: auto;
  overflow-x: auto;
}

.CertificateHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 99vw;
  padding: 10px 0px;
}

.CertificateHeader img {
  height: 90px;
}

.CertificateContent {
  display: flex;
  height: 65vh;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 140px;
  max-width: 100vw;
}

.CertificateLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
}

.CertificateTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-bottom: 50px;
}

.CertificateCentralLogo {
  width: 30vw;
}

.CertificateRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
}

.CertificateForm {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 380px;
  height: 300px;
  padding: 10px 50px;
}

.CertificateForm h3 {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 15px 40px;
  font-size: 20px;
}

.CertificateForm input {
  background-color: white;
  width: 80%;
  padding: 15px 10%;
  border: none;
  border-radius: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.CertificateForm button {
  border: none;
  border-radius: 10px;
  padding: 15px 50px;
  font-size: 16px;
  letter-spacing: 2px;
}

.CertificateSupButton {
  width: 140px;
  font-size: 10px;
  border: white solid 2px;
  background-color: transparent;
  border-radius: 100px;
  padding: 6px;
  margin-right: 10px;
  margin-top: 15px;
  align-self: start;
}

@media (orientation: portrait) {
  .CertificateContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-image: url('../../media/backgroundMobile2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: auto;
  }
}

.canvas {
  display: none;
  visibility: hidden;
  font-family: 'BrandonGrotesque';

  /* position: relative;
  left: 400px;
  display: block;
  background-color: #FFF;
  z-index: 1000; */
}

.preloader {
  width: 70px;
  height: 70px;
  border: 10px solid transparent;
  border-top: 10px solid #002e6d;
  border-radius: 50%;
  animation-name: girar;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 100px), (max-height: 540px), (orientation: portrait) {
  .CertificateHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 99vw;
  }
  .CertificateHeader img {
    margin-top: 10px;
    height: 35px !important;
  }
  .CertificateContent {
    display: flex;
    height: max-content;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 68px;
    max-width: 100vw;
  }
  .CertificateSeparator {
    height: 0px;
    width: 0px;
  }
  .CertificateLeft {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 99vw;
  }
  .CertificateTitle {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 4px;
    margin: 30px 0px;
  }
  .CertificateCentralLogo {
    width: 40vw;
    margin-bottom: 10px;
  }
  .CertificateRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 99vw;
  }
  .CertificateForm {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 250px;
    padding: 10px 50px;
    min-width: 50vw;
  }
  .CertificateForm h3 {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 15px 0px;
    font-size: 14px;
  }
  .CertificateForm input {
    background-color: white;
    width: 80%;
    padding: 10px 10%;
    border: none;
    border-radius: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .CertificateForm button {
    border: none;
    border-radius: 10px;
    padding: 10px 35px;
    font-size: 16px;
    letter-spacing: 2px;
  }
  .CertificateSupButton {
    width: 130px;
    font-size: 10px;
    border: white solid 2px;
    background-color: transparent;
    border-radius: 100px;
    padding: 5px;
    margin-right: 10px;
    margin-top: 10px;
    align-self: start;
  }
  .preloader {
    width: 40px;
    height: 40px;
    border: 8px solid transparent;
    border-top: 8px solid #002e6d;
    border-radius: 50%;
    animation-name: girar;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}