.QuestionsContainer {
  background-color: #13142a;
  min-height: 100vh;
  width: 100vw;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2vw;
  box-sizing: border-box;
}

.QuestionsContainer textarea {
  outline: none;
}

.Questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.QuestionsContainer h1 {
  margin-bottom: 15px;
  font-size: calc(1em + 2vw);
}

.QuestionsContainer h2 {
  margin-bottom: 15px;
  font-size: calc(1em + 1vw);
}

.QuestionsContainer h4, .QuestionsContainer p {
  font-size: calc(1em + 0.5vw);
}

.QuestionsContainer .QuestionsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  min-height: 30vh;
  max-height: 70vh;
  background-color: rgba(143, 143, 143, 0.267);
  padding: 1vw;
  border-radius: 15px;
  width: 96vw;
  box-sizing: border-box;
  box-shadow: 0px 3px 8px rgb(119, 119, 119);
  overflow-y: auto;
}

.QuestionsContainer .QuestionsContent button {
  border-radius: 10em;
  border: none;
  box-sizing: border-box;
  height: calc(1rem + 0.8vw);
  width: calc(1rem + 0.8vw);
  font-size: calc(1rem + 0.4vw);
  padding: 0;
  font-weight: 900;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(226, 95, 95);
}

.QuestionsContainer .QuestionsContent div {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px dotted white;
  padding: 0.5vw 0px;
}

.QuestionsContainer .QuestionsContent div p {
  word-wrap: break-word;
  width: 45%;
}