.AgendaContainer {
  height: max-content;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: unset;
  align-items: center;
  background-image: url('../../media/bg/agendaBG.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: auto;
  overflow-x: hidden;
}

.AgendaHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 99vw;
  padding: 10px 0 0 0;
  margin: 0px auto;
}

.AgendaHeader img {
  height: 5.5vw;
}

.AgendaContent {
  display: flex;
  height: 70vh;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 0px;
  max-width: 100vw;
  margin: auto;
}

.AgendaLeft {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 35vw;
  height: 100%;
}

.AgendaLeft .AngendaLeftInside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AgendaTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-bottom: 50px;
  padding: 30px;

}

.AgendaCentralLogo {
  width: 24vw;
}

.AgendaRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 60vw;
  overflow-x: auto;
  overflow-y: hidden;
}

.AgendaDesktopSection {
  overflow-y: auto;
  background-color: white;
  padding: 15px 10px 10px 20px;
  margin-right: 10px;
  width: 230px;
  box-sizing: content-box;
}

.AgendaDesktop {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.AgendaInsideCalendar {
  width: 105px !important;
}

.AgendaDesktopTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 10px !important;
  height: auto;
  margin-bottom: 10px;
}

.AgendaDesktopTitle a, .AgendaMobileTitle a {
  text-decoration: none;
  color: inherit;
}

.AgendaDesktopTitle h3 {
  width: 215px;
  text-align: center;
  margin-bottom: 1px;
}

.AgendaDesktopTitle div {
  width: 215px;
  margin: 0px !important;
  padding: 0px !important;
  text-align: center;
}

.AgendaList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 62vh;
  padding-left: 0px;
  width: 100%;
}

.AgendaList div .AgendaItem {
  height: auto;
}

.AgendaList div, .AgendaList hr {
  background-color: white;
}

.AgendaList hr {
  border: 1px solid rgb(228, 228, 228);
}

.AgendaList li::marker {
  color: #5ac0e9;
  font-size: 12px;
  font-weight: 900;
  margin: 0;
  padding: 0;
}

.AgendaItem {
  font-size: 14px;
}

.AgendaDuration {
  color: #5ac0e9;
}

.AgendaDesktopEnd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 215px;
  height: auto;
  padding: 0px 15px !important;
  margin-bottom: 3px !important;
}

.AgendaDesktopEnd p {
  font-size: 10px;
  padding: 8px;
  width: 100%;
}

.AgendaSpeaker {
  font-size: 12px;
  font-weight: 400;
}

.AgendaTopic {
  font-size: 12px;
}

/* MOBILE */

.AgendaMobile {
  display: none;
  visibility: hidden;
}

.sliderButtons {
  display: none;
  visibility: hidden;
}

.AgendaMobileSection {
  position: absolute;
  width: 230px;
  height: 300px;
  max-height: 45vh;
  opacity: 0;
  transition: opacity .5s;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: content-box;
}

.AgendaMobileList .AgendaMobileSection:first-child {
  opacity: 1;
  z-index: 10;
}

.AgendaMobileList .AgendaMobileSection:target {
  opacity: 1;
  z-index: 11;
}

.AgendaMobileList div:target {
  opacity: 1;
  z-index: 11;
}

.AgendaMobileTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px !important;
}

.AgendaMobileTitle h3 {
  width: 220px;
  text-align: center;
  margin-bottom: 1px;
}

.AgendaMobileTitle div {
  width: 218px;
  font-size: 12px;
  padding: 0px !important;
  text-align: center;
  font-weight: 900;
}

.AgendaMobileList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 350px;
  padding-left: 0px;
  width: 250px;
}

.AgendaMobileList div, .AgendaMobileList hr {
  background-color: white;
  padding: 0px 10px;
  margin: 0px 0px 0px 0px;
}

.AgendaMobileList hr {
  border: 1px solid rgb(228, 228, 228);
}

.AgendaMobileList li::marker {
  color: #5ac0e9;
  font-size: 10px;
  font-weight: 900;
  margin: 0;
  padding: 0;
}

.AgendaMobileItem {
  font-size: 10px;
}

.AgendaDurationMobile {
  color: #5ac0e9;
}

.AgendaMobileEnd {
  width: 210px;
  padding: 5px !important;
  margin-bottom: 3px !important;
  background-color: #999999 !important;
}

@media (orientation: portrait) {
  .AgendaContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-image: url('../../media/backgroundMobile2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: auto;
  }
}

@media (max-width: 100px), (max-height: 540px),(orientation: portrait) {
  .AgendaHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 99vw;
  }
  .AgendaHeader img {
    margin-top: 10px;
    height: 35px !important;
  }
  .AgendaContent {
    display: flex;
    height: max-content;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 20px;
    max-width: 100vw;
  }
  .AgendaSeparator {
    height: 0px;
    width: 0px;
  }
  .AgendaLeft {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 99vw;
  }
  .AgendaLeft .AngendaLeftInside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .AgendaTitle {
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 10px 0px;
    padding: 5px;
  }
  .AgendaCentralLogo {
    width: 40vw;
    margin-bottom: 10px;
  }
  .AgendaRight {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 99vw;
  }
  .AgendaDesktop {
    display: none;
    visibility: hidden;
  }
  .AgendaMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    visibility: visible;
    width: 250px;
  }
  .sliderButtons {
    display: block;
    visibility: visible;
    text-decoration: none;
    width: 10px;
  }
  .sliderButtons img{
    width: 13px;
  }
  .AgendaMobileScrollDown {
    border: 1px solid #ecd589;
    text-align: center;
    background-color: #002b81;
    max-width: 50vw;
    font-size: 12px;
  }
  .AgendaMobileList {
    height: 300px;
    max-height: 45vh;
    margin: 5px;
  }
}