.SpeakersContainer {
  height: max-content;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-image: url('../../media/bg/ponentesBG.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: auto;
  overflow-x: hidden;
}

.SpeakersHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95vw;
  padding: 10px 0px;
}

.SpeakersHeader img {
  height: 5vw;
}

.SpeakersContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 0px;
  max-width: 100vw;
  margin: auto;
}

.SpeakersLeft {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30vw;
}

.SpeakersLeft .AngendaLeftInside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SpeakersTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-bottom: 50px;
  padding: 15px;
}

.SpeakersCentralLogo {
  width: 24vw;
  margin: auto;
}

.SpeakersRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 69vw;
  height: max-content;
  min-height: 70vh;
  overflow: hidden;
}

.SpeakersTitle {
  margin-left: 10vw;
}

.SpeakersList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 60vh;
  padding-left: 0px;
  width: 250px;
}

.SpeakersList div .SpeakersItem {
  height: 75px;
}

.SpeakersList div, .SpeakersList hr {
  background-color: white;
  padding: 0px 20px;
  margin: 0px 0px 0px 3px;
}

.SpeakersList hr {
  border: 1px solid rgb(228, 228, 228);
}

.SpeakersList li::marker {
  color: #5ac0e9;
  font-size: 12px;
  font-weight: 900;
  margin: 0;
  padding: 0;
}

.SpeakersItem {
  font-size: 14px;
}

.SpeakersDuration {
  color: #5ac0e9;
}

.SpeakersDesktopEnd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 75px;
  padding: 0px 15px !important;
  margin-bottom: 3px !important;
}

.SpeakersDesktopEnd p {
  font-size: 10px;
  padding: 8px;
  width: 100%;
}

/* MOBILE */

@media (orientation: portrait) {
  .SpeakersContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-image: url('../../media/backgroundMobile2.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: auto;
  }
}

@media (max-height: 768px),(orientation: portrait) {
  .SpeakersSliderSection img {
    width: 180px;
  }
}

@media (max-width: 100px), (max-height: 540px),(orientation: portrait) {
  .SpeakersHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 98vw;
  }
  .SpeakersHeader img {
    margin-top: 10px;
    height: 35px !important;
  }
  .SpeakersContent {
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    max-width: 100vw;
    margin: 0px;
  }
  .SpeakersSeparator {
    height: 0px;
    width: 0px;
  }
  .SpeakersLeft {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 99vw;
    margin: auto;
  }
  .SpeakersLeft .AngendaLeftInside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .SpeakersTitle {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 10px 0px;
    padding: 5px;
  }
  .SpeakersCentralLogo {
    width: 40vw;
    margin-bottom: 0px;
  }
  .SpeakersRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 99vw;
    height: auto;
    min-height: auto;
    margin: 0px auto auto auto;
  }
}